<template>
    <div class="audio-preview d-flex flex-column">
        <div class="navbar-preview">
            <div class="container-fluid">
                <div class="row pb-4 d-flex justify-content-between">
                    <div class="col-md-6 d-flex justify-content-start">
                        <img src="@/assets/images/logo/soca-w-logo.svg" class="app-logo" alt="Dashboard"/>
                    </div>
                    <div class="col-md-6 ap__action d-flex justify-content-end">
                        <!-- <button v-if="isVideo" type="button" class="btn btn__ap-export d-inline-flex align-items-center" @click="$bvModal.show('eport-gv-modal')">
                            <img
                                src="@/assets/images/icons/ic_export_tta.svg"
                                class="gv-header__button-icon"
                                alt=""
                                height="14"
                            />
                            Export
                        </button> -->
                        <button type="button" class="btn btn__ap-export mr-2 d-flex align-items-center" @click="toHome()">
                            <span>Open Creator</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex align-content-center flex-wrap second__div">
            <div class="hero-video_pink absolute"></div>
            <div class="hero-video_blue absolute"></div>
            <div class="container">
                <div v-if="newFinalPathTTA != null && loading == false" class="d-flex flex-column align-items-center justify-content-center container__video">
                    <div class="hero-video_purple absolute"></div>
                    <div class="preview__content--video-container position-relative"
                        :class="{
                            'pt-5' : checkEkstensionFile(videoPath) == 'audio'
                        }"
                    >
                        <template v-if="checkEkstensionFile(videoPath) == 'video'">
                            <video id="video-tta" ref="videoPlayer" controls controlsList="nodownload nofullscreen" class="h-100 w-100" oncontextmenu="return false;">
                                <source id="change-src" :src="blobUrl ? blobUrl : newFinalPathTTA" type="video/mp4" class="shadow-video">
                            </video>
	                        <img v-if="$route.query.content == 'autodubb'" src="@/assets/images/WM-Auto-Dubb.png" alt="Dubbed by SOCA" class="watermark">
                        </template>
                        <div v-else-if="checkEkstensionFile(videoPath) == 'audio'" class="audio-control-vo h-100 d-flex flex-column align-items-center justify-content-center">
                            <div class="" id="video-controls-detail">
                                <div class="w-100 mb-4">
                                    <img src="@/assets/images/icons/ic_generates-videos-project.svg" class="img--soca">
                                </div>
                                <div class="w-100 d-flex align-items-center justify-content-center actions--player">
                                    <ADAudioPlayer :src="newFinalPathTTA"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="information__video mt-4">
                        <div class="user__video d-flex flex-column justify-content-start">
                            <span class="mb-2">created by : </span>
                            <h5>{{ data.creator_name ? data.creator_name : '-' }}</h5>
                        </div>
                        <div class="title-banner__video d-md-flex align-items-md-center justify-content-md-between mb-4">
                            <div class="col-md-9 d-md-flex align-items-md-center justify-content-md-start d-flex justify-content-start text-left my-2 my-md-0">
                                <span>Hi socanians, you can create content like this for free!</span>
                            </div>
                            <div class="col-md-3 d-md-flex align-items-md-center justify-content-md-end d-flex justify-content-start mt-4 mt-md-0">
                                <button type="button" class="btn btn__ap-export mr-2" @click="toHome()">
                                    Try for FREE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="loading == true">
                    <div class="spinner-border text-white" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div v-else class="text-center">
                    <h4 class="mb-2">Sorry, Audio currently unavailable!</h4>
                    <span class="text-muted">Looking for audio? Try browsing our trending creators or audio.</span>
                </div>
            </div>
        </div>
        <ExportGv disable-audio :loading-audio='loading_daudio' :loading-video='loading_dvideo' :loading-cl="loading_cl" @click:download-audio="handleDownloadAudio" @click:download-video="handleDownloadVideo" @click:copy-link="handleCopyLink" @reset-egv="handleResetEgv"/>
    </div>
</template>

<script>
import { getShareContent } from "@/services/contents/contents.service";
import { getAutodubPreview } from "@/services/auto-dub/autodub.service";
import { AlertUtils } from "@/mixins/AlertUtils";
import { exportUtils } from "@/mixins/ExportUtils";
import { base_url_auto_dubbing } from '@/config/base_url';

import ADAudioPlayer from './AudioPlayer.vue'
import ExportGv from '@/components/modal/ExportGv.vue'
import axios from "axios"

export default {
    mixins: [AlertUtils, exportUtils],
    components: {
        ExportGv,
        ADAudioPlayer
    },
    data(){
        return {
            blobUrl: null,
            videoPath: null,
            loading: true,
            loading_daudio: false,
            loading_dvideo: false,
            loading_cl: false,
            isVideo: false,

            data: []
            // 'https://stg-ml.soca.ai/static/audio/635246638d03187e6229a9d0/640aae25321d971566650cf8/final.mp4'
        }
    },
    mounted(){
        if(this.$route.query.type) {
            this.isVideo = true
        }

        if(this.$route.query.content == 'autodubb') {
            this.getPreviewAutodubb()
        } else {
            this.getAudio()
        }
    },
    computed: {
        newFinalPathTTA(){
            return this.videoPath;
        }
    },
    methods: {
        toHome(){
            if(localStorage.users){
                this.$router.push({ name : 'Home' });
            }else{
                this.$router.push({ name : 'SignIn' });
            }
        },
        getAudio(){
            this.loading = true
            getShareContent(this.$route.params.id)
            .then((response) =>{
                if(response.status == true) {
                    this.loading = false
                    this.data = response.data
                    this.videoPath = response.data.content_file
                } else {
                    this.loading = false
                }
            })
            .catch(err => {
                console.log(err);
            })
        },

        async getPreviewAutodubb(){
            this.loading = true
            await getAutodubPreview(this.$route.params.id)
            .then((response) =>{
                if(response.status == true) {
                    this.data = response.data
                    this.videoPath = base_url_auto_dubbing + response.data.content_file.replace('src/', '')
                    this.parseVideoToBlob(this.videoPath)
                } else {
                    this.loading = false
                }
            })
            .catch(err => {
                console.log(err);
            })
        },

        handleDownloadAudio() {

        },

        handleDownloadVideo() {
            this.loading_dvideo = true
            axios.get(this.videoPath, { responseType: 'blob' })
            .then((res) => {
                this.exportVidio(res.data, this.data.content_name ? this.data.content_name : 'Video - SOCA AI')
                this.alertSuccess(this.$t('content-successfully'))
                this.loading_dvideo = false
            })
            .catch((err) => {
                console.log(err)
                this.alertFail('Something went wrong !')
                this.loading_dvideo = false
            })
        },

        handleCopyLink() {
            const url = window.location.href;
            navigator.clipboard.writeText(url)
            .then(() => {
                this.alertSuccess('Link successfully copied')
                this.loading_cl = false
            })
            .catch(() => {
                this.alertFail('Link failed to copy');
                this.loading_cl = false
            })
        },

        handleResetEgv() {
            this.loading_cl = false
            this.loading_daudio = false
            this.loading_dvideo = false
        },

        async parseVideoToBlob(url) {
			var vm = this
			vm.isBlob = true

			const axiosInstance = axios.create({
				responseType: 'blob',
				headers: {
					'Cache-Control': 'no-cache',
				},
			});

			await axiosInstance.get(url, {
				responseType: 'blob',
				headers: {
					'Cache-Control': 'no-cache',
				},
			})
            .then(res => {
				vm.blobUrl = URL.createObjectURL(res.data);
				vm.isBlob = false
                vm.loading = false
            })
            .catch((error) => {
				vm.isBlob = false
                vm.loading = false
				console.error(error)
            })
		},

        checkEkstensionFile(file) {
            if(file) {
                let extension = file.substring(file.lastIndexOf('.')).toLowerCase()
                if( extension == '.mp3' || extension == '.wav') {
                    return 'audio'
                } else {
                    return 'video'
                }
            }
            return null
        },
    }
}
</script>
<style scoped>
    .actions--player {
        gap: 6px;
    }
    .audio-control-vo {
        background: #1f1f1f;
        border: none;
        border-radius: 6px;
    }

    .audio-preview {
        height: 100vh;
    }
    .navbar-preview{
        padding-top: 1.5rem;
        padding-left: 3rem;
        padding-right: 3rem;
        background: rgba(0, 0, 0, 0.30);
        backdrop-filter: blur(5px);
    }
    .container__video{
        border-radius: 50px;
        background: rgba(0, 0, 0, 0.40);
        backdrop-filter: blur(5px);
        border: 0.1px solid rgba(45, 45, 45, 1);
        border-right: bisque;
    }
    .information__video{
        width: 80%;
    }

    .user__video{
        text-align: left;
    }
    .hero-video_purple {
        --a: 0deg;
        position: absolute;
        width: 43%;
        height: 55%;
        top: 144px;
        bottom: 18%;
        left: 28%;
        z-index: -50;
        filter: blur(250px);
        border-radius: 50%;
        background: linear-gradient(135deg, #473FF4 0%, #E52C96 100%) !important;
        background: -webkit-linear-gradient(28deg, #473FF4 0%, #E52C96 100%) !important;
        animation: gradientSpin 10s linear infinite;
        transform: translate3d(var(--tw-translate-x),var(--tw-translate-y),0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        border-radius: 555px;
        background: var(--color-3, linear-gradient(180deg, #7A60E3 0%, #FF83EB 100%));
        filter: blur(150px);
    }

    .hero-video_blue{
        z-index: -40;
        position: absolute;
        width: 50%;
        height: 41%;
        top: 0;
        bottom: 18%;
        right: 0px;
        border-radius: 938px;
        opacity: 0.4000000059604645;
        background: var(--purple-2, linear-gradient(180deg, #58AFFF 11.98%, #663DDA 100%));
        filter: blur(150px);
    }
    .hero-video_pink{
        position: absolute;
        z-index: -12;
        width: 50%;
        height: 23%;
        top: 0;
        bottom: 18%;
        left: -99px;
        border-radius: 832px;
        opacity: 0.30000001192092896;
        background: var(--pink-1, linear-gradient(180deg, #FAE4FE 0%, #FE9EFF 100%));
        filter: blur(100px);
    }
    .title-banner__video span{
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
    }

    #video-tta{
        margin-top: 2.5rem;
        background: black;
    }
    .user__video span{
        color: #585859;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .user__video h5{
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .btn-fill-purple {
    border: 1px solid #6D75F6;
    color: #6D75F6;
    }
    .audio-preview h4{
        color: #ffffff;
    }
    .height-content{
        height: 80vh;
        margin-top: 2rem;
    }


    .btn__ap-export {
        padding-inline: 12px;
        background-color: transparent;
        color: #ffffff;
        gap: 6px;
        padding-block: 8px;
        background-color: #6D75F6;
        font-size: 14px;
        font-weight: 500;
        transition: background-color 0.25s, border-color 0.25s, color 0.25s;
    }


    .btn__ap-export:disabled {
        border-color: #1F1F1F;
        color: #585859;
    }

    .ap__action {
        gap: 8px;
    }

    .second__div {
        flex: 1;
    }

    .preview__content--video-container {
        height: 400px;
        width: 80%;
    }

    .watermark {
        position: absolute;
        bottom: 30px;
        left: 10px; /*50% kalo mau ditengah*/
        /* transform: translateX(-50%); */
        opacity: 0.7
    }

    .img--soca {
        width: 73px;
    }

    @media only screen and (min-width: 300px) and (max-width: 720px)  {
        .watermark {
            display: none;
        }

        .preview__content--video-container {
            padding: 0 18px;
            width: 100%;
        }

        .navbar-preview {
            padding-left: 23px;
            padding-right: 12px;
            /* margin-bottom: 5.8rem !important; */
        }
        .container__video{
            border-radius: 16.428px !important;
            margin-bottom: 3rem !important;
        }
        .hero-video_purple{
            display: none;
        }

        .hero-video_blue{
            width: 60%;
            height: 50%;
            top: 0;
            bottom: 18%;
            right: 0px;
            /* filter: blur(75px); */
            filter: blur(40px);
            background-color: rgb(88,175,255);
            background: radial-gradient(circle, rgba(88,175,255,1) 0%, rgba(102,61,218,1) 100%);
        }
        .hero-video_pink{
            width: 75%;
            height: 22%;
            top: 0;
            bottom: 18%;
            right: 0px;
            /* filter: blur(75px); */
            filter: blur(40px);
            background-color: rgb(250,228,254);
            background: radial-gradient(circle, rgba(250,228,254,1) 0%, rgba(254,158,255,1) 100%);
        }

        .audio-preview {
            gap: 16px;
        }

        .second__div {
            flex: 0;
        }

        .img--soca {
            width: 53px;
        }
    }
</style>